body {
  font-family: 'Lato' !important;
  background-color: #f2f5ff;
  margin: 0px;
  padding: 0px;
}

.App, .sui-layout {
  background-color: #f2f5ff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Header Section **/
button.sui-layout-sidebar-toggle {
  border:1px solid #00C674;
  color: #00C674;
}

.main-container .sui-layout-header {
  padding: 0px;
  border: none;
}
.outer-header {
  width: 100%;
  background: radial-gradient(circle, rgba(52,46,155,0.1) 0%, #261E81 200%), url(../src/assets/architecture-buildings-city.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 101px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 24px;
}
.outer-header .inner-content {
  width: 100%;
  max-width: 998px;
  margin: 0 auto;
  padding: 10px 27px;
}
.outer-header h1 {
  margin: 0px;
  padding: 0px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 350px;
  width: 100%;
}

/** Header Section - END**/

/** Body Container Section **/

.main-container .sui-layout-body__inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin:0px;
    padding: 0px;
    max-width: 100%;
}

    /** Sidebar Section **/

      .main-container .sui-layout-sidebar {
        width: 244px;
        background-color: #fff;
        box-shadow: 0 2px 20px 0 rgba(0,33,64,.08);
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        box-sizing: border-box;
        position: relative;
        z-index: 990;
        top: 1px;
        
      }

      .sui-layout-main {
        width: calc(100% - 244px);
        box-sizing: border-box;
        position: absolute;
        left: 244px;
        height: 100%;
        overflow-y: hidden;
        top: 0;
        padding: 0px 0 80px 0;
      }

      .sui-layout-main-body {
        position: relative;
        overflow-y: hidden;
        height: 100%;
        top: 1px;
        max-width: 100%;
      }

      .sui-layout-main .main-Outer {
        height: 100% !important;
      }

      .sui-layout-main-body .bodyContainer {
        width: 100%;
        overflow-y: scroll;
        max-width: 100%;
        height: 100%;
      }
      .sui-layout-main-body .bodyContainer .innerBody-sec {
        max-width: 80%;
        margin: 30px auto 0;
      }

      .main-container .sui-layout-sidebar.sui-layout-sidebar--toggled {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        margin:0px;
      }

      .main-container .sui-layout-sidebar .sui-multi-checkbox-facet {
        overflow-y: auto !important;
      }

      .listing-box-tags {
        border-radius: 0 0 6px 6px;
      }

      .main-container .sui-layout-sidebar .sui-facet {
        padding-left: 19px;
        padding-right: 19px;
        padding-bottom: 19px;
        border-bottom: 1px solid #f2f3f3;
        margin-top: 19px;
      }

      .main-container .sui-layout-sidebar .sui-multi-checkbox-facet {
        max-height: 95px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .main-container .sui-layout-sidebar .sui-multi-checkbox-facet__option-count {
        margin-right: 8px;
      }
      .main-container .sui-multi-checkbox-facet__option-count {
        margin-left: 15px!important;
      }

      .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 135px;
        line-height: 14px;
        color: #83919F;
        font-size: 11px;
        font-weight: normal;
      }
      
      .main-container .sui-layout-sidebar .sui-facet__title {
        color: #607C98;
        font-size: 12px;
        font-weight: bold;
        text-transform: capitalize;
      }
      
      .main-container .sui-facet {
        font-family: 'Lato' !important;
      }

      .sui-facet-view-more {
        color: #0CAB7C;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 11px;
        border: none;
        outline: none;
        margin-top: 8px;
        margin-left: 5px;
      }

      .sui-facet-view-more:hover, .sui-facet-view-more:focus {
        background-color: transparent;
        outline: none;
      }
      .sui-facet-search__text-input {
        border: 1px solid #CEDDE2;
      }
      .sui-facet-search__text-input:focus {
        border: 1px solid #cccccc;
      }
      
      .sui-facet-search__text-input {
        color: #8AA2AA;
        font-family: Lato;
        font-size: 13px;
      }

      .sui-multi-checkbox-facet {margin-bottom: 0px;}
      .sui-facet-search ::-webkit-input-placeholder {color:#8AA2AA;}
      .sui-facet-search ::-moz-placeholder {color:#8AA2AA;}
      .sui-facet-search :-ms-input-placeholder {color:#8AA2AA;}
      .sui-facet-search :-moz-placeholder {color:#8AA2AA;}

      .sui-layout-sidebar-toggle {
        margin-top: 0px;
      }

      .sui-layout-body {
        background-color:#f2f5ff;
      }

      .sui-layout-sidebar.sui-layout-sidebar--toggled {
        padding-top: 20px !important;
        border-radius: 0 !important;
      }

    /** Sidebar Section - END **/


    /** Body Container **/

    /* .main-container .sui-layout-main {
      width: 714px;
      padding: 0px;
    } */



    .bodyContainer .sui-search-box.custom-search .button.sui-search-box__submit {
      display: none;
    }

    .bodyContainer .sui-search-box__text-input {
      padding: 7px 10px;
      padding-left: 35px;
    }

    .paging-sorting-outer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .paging-sorting-outer .sui-select__control {
      border-radius: 4px;
      background-color: #fff;
      border:1px solid #ccc;
    }
   .sui-select__control--is-focused {
      border: 1px solid #ccc !important
    }

    .paging-sorting-outer .sui-select {
      width: 130px;
      font-size: 11px;
    }

    .paging-sorting-outer .custom-sorting {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .paging-sorting-outer .sui-sorting__label {
      position: relative;
      top: 5px;
      margin-right: 10px;
    }

    .paging-sorting-outer .sui-select__control {
      height: 30px;
    }

    /** Body Container -- END **/

    .sui-layout-body:after {
      height: 0px;
    }

    .sui-search-box__text-input:focus {
      border:0px;
      box-shadow: none;
    }
    .searchBox-outer {
      position: relative;
    }
    .searchBox-outer .sui-search-box__wrapper:before {
      content: '\e090';
      font-family: 'simple-line-icons';
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #83919F;
      font-size: 14px;
      position: absolute;
      z-index: 1;
      left: 13px;
      height: 50px;
      line-height: 50px;
    }

    .sui-search-box__autocomplete-container.custom-search {
      margin:0px !important;
      padding:10px 0 !important;
    }

    .main-container .sui-layout-sidebar .sui-multi-checkbox-facet__option-input-wrapper {
      display: flex;
      align-items: center;
    }

    .searchBox-outer .sui-search-box__submit {
      display: none;
    }

    .infoModal-Outer-BodyContent {
      height: 740px;
      overflow-y: auto;
      width: 743px !important;
    }


    .keyword-outer {
      padding: 5px;
      box-sizing: border-box;
      border-radius: 3px;
      background-color: #EBF0F7;
      color: #2E75BF;
      font-size: 11px;
      font-weight: bold;
      line-height: 13px;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 0px;
      margin-top: 10px;
    }

  .keyword-outer span {
      margin-left: 5px;
      margin-right: 2px;
      cursor: pointer;
  }


  .info-modal_footerBlock_BodyContent  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #2E75BF;
  }
/** Body Container Section - END **/

/** Media Query Layout **/

@media only screen and (max-width:767px) {
  .outer-header h1 {
      font-size: 16px;
      line-height: 24px;
  }
  .outer-header {
      min-height: 60px;
  }
  .listing-box-heading {
    align-items: center;
  }
  .listing-box-more {
    margin:0px auto 0 24px !important
  }
  .listing-box-tags {
    padding: 7px 0;
  }
}

@media only screen and (max-width:800px) {
  .main-container .sui-layout-body__inner {
    flex-direction: column;
  }
  .main-container .sui-layout-main {
    margin-top: 20px;
    width: 100% !important;
    left: 0px;
    top: 35px;
    background: #f2f5ff;
  }
  .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
    width: 90% !important;
  }
  .sui-layout-sidebar {
    z-index: 9999;
  }
  button.sui-layout-sidebar-toggle {
    width: 95%;
    margin: 0 auto;
  }
  .main-container .sui-layout-body__inner {
    background-color: #fff;
  }
  .searchButtonBox {
    padding-right: 2.5%;
  }
  .bitCoin-outer .bitCoin-delete {
    margin-right: 2.5%;
  }
  .sui-layout-main-footer {
    position: relative;
    top: -25px;
    background: #f2f5ff;
  }
  .sui-layout-main-body {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width:800px) and (max-width:1000px) {
  .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
    width: 73px;
  }  
}

@media only screen and (min-width:801px) {
  .searchButtonBox {
    padding-right: 8.5%;
  }
  .bitCoin-outer .bitCoin-delete {
    margin-right: 8.5%;
  }
}

/** Media Query Layout -- END **/


/** Page Scrollbar Layout **/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar-thumb {
  border: 0px solid transparent;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
  border-radius: 5px;
}

* {
  scrollbar-width: thin;
}

/** Page Scrollbar Layout -- END **/

/* html, body {
    overflow: hidden;
 }  */

.layout {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.layout .left-side {
  position:absolute;
  top:0;
  left:0;
  width:240px;
  height:100%;
  display: flex;
}

.App {
  height:100%;
  overflow-x: hidden;
  overflow-y:scroll;
  width:calc(100% - 240px);
  display: flex;
  position: absolute;
  left:240px;
}


.layout .App.search-filter-mob {
    /* left:72px !important;
    width: calc(100% - 72px) !important; */
}

.MuiListSubheader-sticky {
  position: inherit !important;
}


.searchBox-outer input.sui-search-box__text-input {
  padding: 15.5px 15.5px 15.5px 40px;
  border:0px;
}
.searchBox-outer {
    width: 100%; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1); 
}

.searchInputBox {
  width:95%;
}

.sui-layout-header__inner {
    position: relative;
    background: #fff;    
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    width: 100%;
}

.searchBox-outer .searchButtonBox button {
  background-color: #4B51DD;
  height: 30px;
  width: 108px;
  color: #fff;
  border:none;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 3px 11px 0 rgba(41,110,179,0.4);
  font-family: 'Lato' !important;
  font-weight: bold;
  font-size: 13px;
}

.searchButtonBox div {
  z-index: 9;
}


.main-container .sui-layout-sidebar .sui-facet__title {
   color: #2E75BF; 
}

@media only screen and (min-width:1025px) {
  .main-container .sui-layout-sidebar .sui-facet span.sui-multi-checkbox-facet__input-text {
    font-size: 12px;
    color: #555555;
  }
  .main-container .sui-multi-checkbox-facet__option-count {
    font-size: 12px;
    color: #555555;
  }
  .main-container .sui-facet-view-more {
    font-size: 12px;
  }
}


@media only screen and (max-width:990px) {
  .App.search-filter-mob {
    width: calc(100% - 72px);
    left: 72px;
  }
}

@media only screen and (max-width:812px) {
  .MuiDialog-paperWidthSm {
    width: 90%;
  }
  .topHeader .outer_selection, .topHeader .rightHeader_item {font-size: 12px;}
}


@media only screen and (max-width:568px) {
  .topHeader .outer_selection, .topHeader .rightHeader_item {
    font-size: 10px;
  }
  .sui-layout-main-body .bodyContainer .innerBody-sec {
    width: 61% !important;
  }
  .listing-box-heading h2 {
    font-size: 12px;
    line-height: 18px;
  }
  .tags-listing {
    font-size: 12px;
  }
  .rc-pagination-item, .rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next {
    min-width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .paging-sorting-outer {
    flex-direction: column
  }
}


@media only screen and (max-width:812px) and (orientation: landscape) {
  .radio_items .innerRadio_box {
    max-height: 80px !important;
  }
}

@media only screen and (min-width:768px) {
  .modal-dialog-outer {max-width: 600px !important;}
  .infoModal-Outer-BodyContent {height: 390px !important;}
}

@media only screen and (min-width:1600px) {
  .infoModal-Outer-BodyContent {height: 690px !important;}
}


@media only screen and (min-width:768px) and (max-width:1279px) {
  .infoModal-Outer-BodyContent {
    width: 610px !important;
  }
}

@media only screen and (min-width:801px) and (max-width:1023px) {
  .topHeader .innerHeader {
    flex-direction: column;
  }
  .topHeader .leftHeader_item {
    margin-bottom: 10px;
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .infoModal-Outer-BodyContent {
    width: 768px !important;
  }
  .dropDown-multipleOpt {
    right: -258px !important;
  }
  .menu-links-outer  .itemName {
    float: left;
    width: 90%;
  }
  .menu-links-outer  .itemValue {
    text-align: right;
    width: 20px;
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 991px) {
.mobileMenu.sidebarMenu-outer .itemName .MuiListItemText-root {
  display: none !important;
}
.mobileMenu.sidebarMenu-outer .itemValue {
  display: none !important;
}
}

@supports (-ms-ime-align:auto) {
  .infoModal-Outer-BodyContent {
    width: 768px !important;
  }
  .dropDown-multipleOpt {
    right: -258px !important;
  }
  .main-container .sui-layout-sidebar .sui-facet {
    padding-left: 15px;
    padding-right: 15px;
  }
}