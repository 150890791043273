.searchButtonBox ul li {
    color: #13334D;
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 7px;
}
.searchButtonBox ul li.active {
    color: #4A50DC;
}
.searchButtonBox ul li span:first-child {
    margin-right: 10px;
}
.searchButtonBox ul li:last-child {
    margin-bottom: 0px;
} 