/**  Main Section **/
    .disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
    .pointer {
        cursor: pointer;
    }
    .search_clear_outer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        flex-wrap: wrap;
    }

    .search_clear_outer div {
        color: #1a2838;
        background: #c1d1db;
        border-radius: 3px;
        margin: 2px 3px;
        padding: 3px 8px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .search_clear_outer div span span {
        margin-left: 10px;
    }

    .main_searchclear_box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .main_searchclear_box div {
        margin-right: 5px !important;
    }

    .search_clear_outer div div {
        margin:0px;
        padding: 0px;
    }

    .main_searchclear_box strong {
        margin-right: 5px;
    }





/* Popup Modal */

.common-modal-outer {
    max-width: 485px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,33,64,0.08);
    margin:0 auto;
}

.common-modal-outer .modal-Heading {
    color: #13334D;
    font-family: Lato;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 12px;    
    padding: 15px 20px;
}

.common-modal-outer .modal-bodyContent {
    padding: 23px 20px;
    border-top: 2px solid #CEDDE2;
    border-bottom: 2px solid #CEDDE2;
}

.common-modal-outer .modal-bodyContent input {
    width: 100%;
    height: 30px;
    border: 1px solid #AFC9D1;
    border-radius: 3px;
    box-sizing: border-box;
    color: #768B9E;
    padding: 0 15px;
}

.common-modal-outer .modal-bodyContent input::-webkit-input-placeholder {color: #768B9E;}
.common-modal-outer .modal-bodyContent input::-moz-placeholder {color: #768B9E;}
.common-modal-outer .modal-bodyContent input:-ms-input-placeholder {color: #768B9E;}
.common-modal-outer .modal-bodyContent input:-moz-placeholder {color: #768B9E;}

.common-modal-outer .modal-footer {
    text-align: center; 
    padding:15px 20px;
}

.common-modal-outer .modal-footer button.save_btn {
  height: 30px;
  border-radius: 4px;
  background-color: #8BBF39;
  border: 0px;
  outline: none;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.26px;
  margin: 0 10px;
  cursor: pointer;
  padding: 0 10px;
}

.common-modal-outer .modal-footer button:disabled {
    height: 30px;
    border-radius: 4px;
    background-color: #c4e78d;
    border: 0px;
    outline: none;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.26px;
    margin: 0 10px;
    cursor: pointer;
    padding: 0 10px;
  }

.common-modal-outer .modal-footer button span {
    margin-right: 10px;
}
.common-modal-outer .modal-footer button.cancel_btn {
    height: 30px;
    border-radius: 4px;
    background-color: #B8C1D3;
    border: 0px;
    outline: none;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.26px;   
    margin: 0 10px;
    cursor: pointer;
    padding: 0 10px;
}

/* Popup Modal -- END */

.bitCoin-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 5px 23px 20px;
    background-color: #fff;
}

.bitCoin-outer .bitCoin-logo {
    color: #000000;
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
}

.bitCoin-outer .bitCoin-logo span.fa-folder-open {
    color:#0da96f;
    margin-right: 7px;
}

.bitCoin-outer .bitCoin-delete {
    box-sizing: border-box;
    height: 31px;
    width: 31px;
    border: 1px solid #4A4BE1;
    border-radius: 3px;
    box-shadow: 0 3px 11px 0 rgba(41,110,179,0.4);
    text-align: center;
    line-height: 31px;
    cursor: pointer;
}

.bitCoin-outer .bitCoin-delete span {
    color: #4B51DD;
    font-size: 13px;
}

.mT30 {
    margin-top: 30px;
}
.common-modal-outer .seachQuestionSet { 
    position: relative;
}
.common-modal-outer .seachQuestionSet input {
    border: 1px solid #E2E7FF;
    border-radius: 3px;
    background-color: #F7F8FC;
    color: #A2A6BF;
    font-family: Lato;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
    position: relative;
    padding-left: 30px;
}

.radio_items {
    margin-top: 25px;
}

.radio_items .innerRadio_box {
    max-height: 190px;
    overflow-y: scroll;
    padding: 10px;
    margin: -10px;
}

.radio_items .MuiRadio-colorSecondary.Mui-checked {
    color: #8BBF39;
}

.common-modal-outer .seachQuestionSet::before {
    content: '\e090';
    font-family: 'simple-line-icons';
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #83919F;
    font-size: 10px;
    position: absolute;
    z-index: 1;
    left: 13px;
    height: 30px;
    line-height: 33px;
}


.info-modal {
    width: 643px;
    margin:0 auto 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.info-modal-bodyContent {
    width: 485px;    
}

.info-modal_headingBlock {
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px 20px;
    box-sizing: border-box;
}

.info-modal_ContentBlock {
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.info_question_status {
    display: flex;
    margin-top: 20px;
}

.info_question_createdBy .createdBy_title, .info_question_Select .status_title {
    color: #778B9D;
    font-size: 10px;
    line-height: 12px;
}

.info_question_createdBy {
    margin-right: 20px;
    border-right: 1px solid #ddd;
    padding-right: 20px;
}

.info_question_createdBy .createdBy_value {
    color: #778B9D;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    margin-top: 5px;
}

.status_SelectOption .MuiInputBase-root::before {
    display: none;
}
.status_SelectOption .MuiInputBase-input {
    padding: 0px;
}

.info-modal-bodyContent h2 {
    color: #13334D;
    font-size: 15px;
    font-weight: 900;
    line-height: 20px;
    margin: 0px;
}

.info-modal-actionBtn {
    width: 148px;
}

.related_question_Header {
    color: #13334D;
    font-size: 10px;
    font-weight: 900;
    line-height: 12px;
    border-bottom: 1px solid #CEDDE2;
    padding: 15px 20px;
}

.related_question_Footer {
    color: #768B9E;
    font-size: 11px;
    line-height: 13px;
    border-top: 1px solid #CEDDE2;
    padding: 15px 20px;
}

.related_question_Body {
    padding: 15px 20px;
}

.related_question_Footer button {
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
    color: #768B9E;
    font-size: 11px;
    line-height: 13px;
    text-transform: inherit;
    font-weight: normal;
}
.related_question_Footer button:hover {
    background-color: transparent;
    box-shadow: none;   
}
.related_question_Footer  span.MuiTouchRipple-root {
    display: none;
}

.related_question_Footer button span.fa-plus{
    color: #245C95;
    font-size: 10px;
    margin-top:2px;
    margin-right: 5px;
}

.related_question_Footer button:active {
    box-shadow: none;
}

.search_outer {
    position: relative;
}
.search_outer_keywords {
    position: relative;
}

.related_question_Body_search input {
    border: 1px solid #AFC9D1;
    border-radius: 3px;
    background-color: #fff;
    width: 100%;
    padding: 7px 7px 7px 7px;
    box-sizing: border-box;
    color: #768B9E;
    font-size: 11px;
    line-height: 13px;
    position: relative;
}

.related_question_Body_search input::-webkit-input-placeholder {color: #768B9E;}
.related_question_Body_search input::-moz-placeholder {color: #768B9E;}
.related_question_Body_search input:-ms-input-placeholder {color: #768B9E;}
.related_question_Body_search input:-moz-placeholder {color: #768B9E;}

.search_outer:before {
    position: absolute;
    left: 10px;
    /* content: '\f067'; */
    color: #245C95;
    font-size: 10px;
    font-family: 'FontAwesome';
    top: 7px;
    z-index: 1;
}
.search_outer_keywords:before {
    position: absolute;
    left: 10px;
    content: '\f067';
    color: #245C95;
    font-size: 10px;
    font-family: 'FontAwesome';
    top: 7px;
    cursor: pointer;
    z-index: 1;
}

.addEvent .fa {
    position: absolute;
    left: 6px;
    color: #245C95;
    font-size: 10px;
    top: 5px;
    z-index: 1;
    padding: 4px;
    cursor: pointer;
}

.keywordsOuter .search_outer:before {
    display: none;
}

.related_question_Body_selectOpt {
    color: #183147;
    font-size: 11px;
    line-height: 13px;
}

.related_question_Body_selectOpt .MuiInputBase-root {
    width: 100%;
}

.related_question_Body_selectOpt .MuiInput-underline:before {
    display: none;
}

.MuiMenu-list li {
    color: #183147;
    /* font-size: 11px;
    line-height: 13px; */
}

.related_question_Body_search .selected_item {
    color: #13334D;
    font-size: 11px;
    font-weight: bold;
    line-height: 17px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,33,64,0.08);
    padding: 7px;
    display: inline-block;
    margin-bottom: 5px;
}

.related_question_Body_search .selected_item span.fa-times {
    color: #2E75BF;
    margin-left: 10px;
    font-size: 11px;
    cursor: pointer;
}

.related_question_Body_search {
    border-left: 1px solid #2E75BF;
    padding-left: 10px;
}

.info-modal_footerBlock {
    background-color: #fff;
}

.info-modal_footerBlock_Header {
    color: #13334D;
    font-size: 10px;
    font-weight: 900;
    line-height: 12px;
    border-bottom: 1px solid #CEDDE2;
    padding: 15px 20px;
}

.info-modal_footerBlock_BodyContent {
    padding: 15px 20px;
    border-bottom: 1px solid #CEDDE2;
    position: relative;
}

.info-modal_footerBlock .info-modal_footerBlock_BodyContent:last-child {
    border-bottom: none;
}

.info-modal_footerBlock_subHeading {
    color: #778B9D;
    font-size: 10px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 10px;
}

.info-modal_footerBlock_subHeading .icon-compass {
    margin-right: 5px;
}

.info-modal_footerBlock_selectItem div {
    padding: 5px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #EBF0F7;
    color: #2E75BF;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.info-modal_footerBlock_selectItem div span {
    margin-left: 5px;
    margin-right: 2px;
    cursor: pointer;
}

.showMore_item {
    box-sizing: border-box;
    height: 38px;
    width: 38px;
    border: 1px solid rgba(46,117,191,0.21);
    border-radius: 19px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,33,64,0.08);
    position: absolute;
    top: 50%;
    right: -40px; 
    background: #fff;				
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%); 
    -ms-transform: translate(-50%, -50%); 
    -o-transform: translate(-50%, -50%); 
    transform: translate(-50%, -50%); 
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    display: none;
}

.info-modal_footerBlock_BodyContent:hover .showMore_item {
    display: block;
}

.info-modal_footerBlock_BodyContent.active .showMore_item {
    display: none;
}

.showMore_item span {
    color: #2E75BF;
}

.info-modal-actionBtn .action_btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.info-modal-actionBtn .action_btn:last-child {
    margin-bottom: 0px;
}

.info-modal-actionBtn .action_btn_icon {
    height: 35px;
    width: 35px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,33,64,0.2);
    color: #2E75BF;
    font-size: 15px;    
    text-align: center;
    line-height: 35px;
}

.info-modal-actionBtn .action_btn_icon span {
    font-size: 14px;
}

.info-modal-actionBtn .action_btn_icon span.fa-floppy-o {
    font-size: 15px;
}

.info-modal-actionBtn .action_btn_value {
    color: #2E75BF;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    padding-left: 10px;
}
.MuiRadio-colorPrimary.Mui-checked {
    color: #2E75BF!important;
}
.info-modal-actionBtn .action_btn:hover .action_btn_icon span, 
.info-modal-actionBtn .action_btn:hover .action_btn_value {
    color: #929FAD;
}
.action_btn_dark {
    color: #0E0A32!important;
    cursor: auto!important;
}
.radio_items .MuiFormGroup-root {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.radio_items .MuiFormGroup-root .MuiFormControlLabel-root {
    width: 49%;
    margin: 0px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,33,64,0.18);
    padding: 2px 5px;
    margin-bottom: 10px;
}

.radio_items .MuiFormGroup-root .MuiFormControlLabel-label {
    color: #13334D;
    font-size: 12px;
    line-height: 15px;
    margin-left: 3px;
}

.radio_items .MuiIconButton-root {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}



.upload_header {
    border-bottom: 1px solid #CEDDE2;
    padding: 15px 25px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.upload_header_icon {
    height: 87px;
    width: 87px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 30px 0 rgba(113,138,155,0.3);
    color: #3984c8;
    font-size: 60px;
    line-height: 60px;
    margin-right: 25px;
    padding: 15px;
}

.upload_header_content h3 {
    color: #3E4246;
    font-size: 13px;
    line-height: 18px;
}

.upload-wrap {
    position: relative;
}

.upload-wrap .MuiButton-label {
    border-radius: 3px;
    background-color: #4B51DD;
    box-shadow: 0 3px 11px 0 rgba(41,110,179,0.4);
    padding: 7px 12px 7px 10px;
}

.upload-btn {
    position: absolute;
    left: 0;
    opacity: 0;
}

.upload-wrap button {
    color: #FFFFFF;
    font-size: 11px;
    line-height: 13px;
    margin:0px;
    padding: 0px;
    border: none;
    outline: none;  
}

.upload-wrap button span.icon-plus {
    margin-right: 10px;
    font-size: 13px;
}

.upload_bodyContent {
    padding: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #CEDDE2;
}

.upload_bodyContent_title {
    color: #3E4246;
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 15px;
}

.upload_bodyContent p {
    color: #4A5658;
    font-size: 11px;
    line-height: 16px;
}


.MuiBackdrop-root {
    background-color: #f2f5ff !important;
}

.modal-dialog-outer {
    max-width: 485px;
    width: 90%;
}

.main-container {
    overflow: hidden;
}

.sui-layout-body {
    height: 100%;
    overflow: hidden;
}

.modalOuter {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    z-index: 1300;
    right: 0px;
    bottom: 0px;
    top: 40px !important;
    left: 240px !important;
}

.modalOuter .MuiBackdrop-root {
    left: 240px;
}

.modalOuter .MuiDialog-scrollPaper {
    align-items: flex-start;
}
.modalOuter .MuiDialog-paper {
    margin:0px;
}

.topHeader {
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0,33,64,0.1);
    padding: 15.5px 0;    
}

.topHeader .innerHeader {
    max-width: 80%;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftHeader_item {
    color: #6E8AA1;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
}

.rightHeader_item {
    display: flex;
    justify-content: flex-start;
    color: #2E75BF;    
    font-size: 13px;
    font-weight: bold;
}

.rightHeader_item .sortOpt .MuiSelect-select {
    padding-bottom: 0px; 
}
.rightHeader_item .sortOpt .MuiInput-underline::before {
    display: none;
}

.rightHeader_item .sortOpt .MuiSelect-icon {
    color: #2E75BF;    
}
.MuiMenu-list li {
    color: #2E75BF;
    font-size: 13px;
    font-weight: bold;
}

.outer_selection {
    color: #2E75BF;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

.outer_selection .selected_item {
    padding-right: 5px;
}
.outer_selection .selected_item::after {
    content:'|';
    color: #2E75BF;
    font-size: 12px;
    position: relative;
    top: -1px;
    left: 5px;
}
.outer_selection .select_all_item {
    padding-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}
.outer_selection .selected_item span {
    margin-right: 5px;
}

.selected_moreOption {
    display: flex;
}

.selected_moreOption div {
    margin-right: 24px;
    cursor: pointer;
}
.selected_moreOption div span {
    margin-right: 5px;
}
.selected_moreOption div:last-child {
    margin-right: 0px;
}

.dropDown-multipleOpt ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    height: 170px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.dropDown-multipleOpt ul li {
    color: #69727A;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
    border-bottom: 1px solid #EAF1F3;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 225px;
    background-color: #f7f9fa;
}
.dropDown-multipleOpt ul li span.fa-plus {
    color: #2E75BF;
    font-size: 10px;
}

.dropDown-Heading {
    color: #24282C;
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
}
.cursor-set {
    cursor: pointer;
}
.dropDown-multipleOpt {
    position: absolute;
    right: -245px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%); 
    -ms-transform: translate(0, -50%); 
    -o-transform: translate(0, -50%); 
    transform: translate(0, -50%); 
    display: flex;
    align-items: center;
}

.dropDown-multipleOpt li span.fa {
    cursor: pointer;
}

.dropDown-headingOuter {
    background: #fff;
    padding: 13px 15px 7px;
    border-radius: 5px 5px 0 0;
    margin-top: 30px;
}

.arrow-left {
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #f7f9fa;
}

.dropDown-multipleOpt .MuiInputBase-root {
    width: 100%;
}


.dropDown-multipleOpt ul {
    border-top: 1px solid #dce4e6;
}

.dropDown-multipleOpt .MuiSelect-select.MuiSelect-select {
    background-color: #fff;
    padding: 0px;
}

.dropDown-multipleOpt .MuiInputBase-root::before {
    display: none;
}

.dropDown-multipleOpt .MuiSelect-icon {
    color: #1E88F9;
}

.dropDown-multipleOpt ul li:last-child {
    border-radius: 0 0 5px 5px;
}

.sample_download {display: flex;align-items: center; margin-top: 20px;}
.sample_download strong a {color: #2E75BF;font-family: Lato;font-size: 11px;font-weight: bold; margin-left: 7px; text-decoration: none;}
.sample_download span { color: #2E75BF;font-size: 13px;letter-spacing: 0;}
.fa-file-excel-o {color: #2E75BF;  font-size: 11px;}
*,*:focus,*:hover{
    outline:none;
}

@media only screen and (max-width:990px) {
    .modalOuter .MuiBackdrop-root, .modalOuter {
        left: 72px !important;
    }
}

@media only screen and (max-width:325px) {
    .common-modal-outer .modal-footer button {
        width: 100px;
    }
    .common-modal-outer .modal-footer button:first-child {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width:600px) {
    .searchButtonBox ul li {
        min-height: inherit;
    }
}
