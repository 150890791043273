.left-side .MuiDrawer-paperAnchorDockedLeft {
background: linear-gradient(306.65deg, #0E0A32 0%, #261E81 100%);
box-shadow: 0 0 10px 3px rgba(93,110,127,0.17);
}

.left-side ul.MuiList-root {
color: #fff;
}

.left-side .MuiSvgIcon-root {
fill: #fff;
}

.left-side .MuiDivider-root {
background-color: rgba(99,198,209,0.2);
}

.left-side .MuiTypography-body1 {
font-size: 13px;
line-height: 16px;
}

.left-side .MuiListItemIcon-root {
color: #fff;
text-align: center;
min-width: auto;
margin-right: 10px;
font-size: 12px;
}

.left-side .sidebarMenu-outer {
display: flex;
flex-direction: column;
height: 100%;
justify-content: space-between;
}

.left-side .mobileMenu .MuiListItemIcon-root {
min-width: 72px;
padding-left: 10px;
}

.left-side .mobileMenu img.covid-logo {
display: none;
}

.left-side .mobileMenu .MuiListSubheader-inset {
    text-align: center;
    white-space: normal;
    padding: 0px !important;
}

.left-side .mobileMenu .MuiIconButton-root {
    position: relative;
    left: -6px;
}

.left-side .MuiListSubheader-inset {
padding-left: 16px;
color: rgba(121,186,252,0.62);
font-family: "Open Sans";
font-size: 10px;
font-weight: bold;
line-height: 14px;
margin-top: 0px;
margin-bottom: 8px;
}

.left-side .MuiListItem-button:hover {
background: linear-gradient(270deg, #0B0563 0%, #4C52DE 100%);
box-shadow: 0 0 10px 3px rgba(93,110,127,0.17);
}

.left-side .MuiListItem-button.active {
    background: linear-gradient(270deg, #0B0563 0%, #4C52DE 100%);
    box-shadow: 0 0 10px 3px rgba(93,110,127,0.17);
    }

/* .left-side .MuiListItem-button:hover span {font-weight: bold;} */

/* .left-side .MuiListItem-button.active span {font-weight: bold;} */

.left-side .MuiDrawer-paper {
justify-content: space-between;
}

.itemName {
    display: flex;
    align-items: center;
}

.bottom-sidebar {
    color: #fff;
    border-top: 1px solid rgba(99,198,209,0.2);
}

.outer-sidebar-logout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 16px 0;
    margin-bottom: 20px;
}

.logout-user-img {
    width: 35px;
    height: 35px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
}

.logout-user-text .logout-user-name {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
}

.logout-user-text .logout-app {
    color: #0CAB7C;
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    cursor: pointer;
}

.left-side .mobileMenu .logout-user-img {
    display: none;
}

.left-side .mobileMenu .logout-user-name {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 43px;
}


.left-side img.covid-logo-symbol {
    display: none;
}

/* .menu-links-outer .MuiButtonBase-root.active .MuiListItemText-root span {
    font-weight: bold;
} */

.menu-links-outer .MuiButtonBase-root.active {
    background: linear-gradient(270deg, #0B0563 0%, #4C52DE 100%);
    box-shadow: 0 0 10px 3px rgba(93,110,127,0.17);
}

.MuiCollapse-wrapper .MuiListItem-gutters {
    padding-left: 41px !important;
}

.MuiCollapse-wrapper {
    background: #140F4F;
}

.top-sidebar div {
    justify-content: space-between !important;
}

@media only screen and (max-width:991px) {
    .left-side img.covid-logo-symbol {
        display: none;
    }
    .left-side .mobileMenu img.covid-logo-symbol {
        display: block !important;
    }
    .MuiCollapse-wrapper .MuiListItem-gutters {
        padding-left: 16px !important;
    }
    .left-side .MuiCollapse-wrapper .MuiListItem-gutters {
        padding-left: 41px !important;
    }
    .left-side .mobileMenu .MuiCollapse-wrapper .MuiListItem-gutters {
        padding-left: 16px !important;
    }
    .top-sidebar .toggleMenu svg {
        transform: none;
    }
    .left-side .mobileMenu .toggleMenu svg {
        transform: rotateZ(-180deg);
    }
}
@media only screen and (min-width:992px) {
    .top-sidebar .toggleMenu {
        display: none !important;
    }
}

.MuiListItem-root {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.top-sidebar div.MuiButtonBase-root {
    justify-content: space-between !important;
}
.itemValue {
    font-size: 12px;
    color: #807cab;
}

.seachQuestion {
    width: 100%;
    height: 41px;
    background: linear-gradient(306.65deg, #0E0A32 0%, #261E81 100%);
    box-shadow: 0 0 10px 3px rgba(93,110,127,0.17);
    margin-bottom: 15px;
}
.seachQuestion input {
    color: #83919F;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    width: 100%;
    border: 0;
    outline: 0;
    height: 41px;
    background: transparent;
    padding: 0 15px 0 33px;
    position: relative;
}

.seachQuestion::before {
    content: '\e090';
    font-family: 'simple-line-icons';
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #83919F;
    font-size: 10px;
    position: absolute;
    z-index: 1;
    left: 13px;
    height: 40px;
    line-height: 43px;
}

.seachQuestion input::-webkit-input-placeholder {color: #83919F;}
.seachQuestion input::-moz-placeholder {color: #83919F;}
.seachQuestion input:-ms-input-placeholder {color: #83919F;}
.seachQuestion input:-moz-placeholder {color: #83919F;}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 991px) {
    .MuiListItem-root {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    .left-side .mobileMenu img.covid-logo-symbol {
        height: 20px;
    }
}