/**  Card Section **/
    
    .listing-box {
        padding: 0;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 4px 17px 3px rgba(0,0,0,0.11);
        box-sizing: border-box;
        cursor: pointer;
      }
  
      .listing-box:hover {
        box-shadow: 0 4px 17px 3px rgba(0,0,0,0.20);
      }
  
      .listing-box-header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 19px 24px 14px;
      }
  
      .listing-box-header .listing-box-logo {
        height: 60px;
        width: 60px;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 0 9px 2px rgba(41,63,55,0.19);
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .listing-box-header .listing-box-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
      }
  
      .listing-box-title .author {
        color: #149A82;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 13px;
        margin-bottom: 3px;
      }
  
      .listing-box-title .author a {
        color: #149A82;
        text-decoration: none;
      }
  
      .author .fa-link {
        color: #149A82;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 10px;
        margin-right: 5px;
        transform: scaleX(-1);
      }
  
      .listing-box-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
  
      .listing-box-heading .listing-box-more {
        margin-left: 24px;
        cursor: pointer;
      }
  
      .listing-box-more a {
        text-decoration: none;
        display: block;
      }
  
      .listing-box-more span {
        color: #169781;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 25px;
      }
  
      .listing-box-heading h2{
        color: #1A2838;
        font-size: 15px;
        font-weight: 900;
        letter-spacing: 0.1px;
        line-height: 20px;
        margin:0px;
        width: 100%;
      }
  
      .listing-box-tags {
        background-color: #F6F9FA;
        padding: 7px 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
  
      .tags-list span.tag-list-icon {
        color: #99aeb5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        margin-right: 9px;
      }
      .tags-listing {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      }
  
      .tags-list {
        padding-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-right: 1px solid #ccc;
        padding-left:10px;
        line-height: 15px;
        padding-bottom: 5px;
      }
/* 
      .tags-list:first-child {
        padding-left: 0px;
      } */

      .tags-list:last-child {
        border-right: 0px solid #ccc;
      }
  
      .tags-listing-item {
        border: 1px solid #C1D1DB;
        border-radius: 3px;
        background-color: #f6f9fa;
        padding: 2.5px 7px;
        text-align: center;
        color: #8AA2AA;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        margin-right: 3px;
        max-width: 70px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
  
      .tags-listing-item.count-item {
        margin-right: 0px;
        padding: 2.5px 3.5px;
        cursor: pointer;
      }
  
      .divider-tags {
        margin: 0 13px;
        border-right: 1px solid #C5D3D7;
        width: 1px;
        height: 17px;
      }
  
      .listing-footer {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 14px 24px 15px;
        align-items: center;
      }
  
      .listing-footer .location {
        color: #6D828C;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 15px;
      }
  
      .listing-footer .publish {
        color: #6D828C;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 15px;
      }
  
      .listing-footer .divider-tags {
        margin: 0 7px;
        border-right: 1px solid #C5D3D7;
        width: 1px;
        height: 12px;
      }
  
  
      @media only screen and (max-width:767px) and (orientation: Portrait) {
        .listing-box-tags {
            flex-direction: column;
            align-items: flex-start;
        }
        .listing-box-tags .tags-list {
          border-right: 0px;
        }
        .listing-box-tags .divider-tags {
            margin: 10px 0;
            background: #C5D3D7;
            width: 100%;
            height: 1px;
        }
        .tags-listing-item {
            margin: 3px;
        }
        .topHeader .innerHeader {
          flex-direction: column;
        }
        .topHeader .innerHeader .leftHeader_item {
          margin-bottom: 10px;
        }
        .sui-layout-main-body .bodyContainer .innerBody-sec {
          width: 70%;
        }
        .listing-box-heading h2 {
          font-size: 13px;
          line-height: 19px;
        }
      }
  
      @media only screen and (max-width:767px) and (orientation: Portrait) {
        .paging-sorting-outer {
          flex-direction: column;
        }
        .paging-sorting-outer .sui-paging-info {
          margin-top: 10px;
        }
      }
.outerCard {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.outerCard:last-child {
  padding-bottom: 50px;
}
.outerCard .selectionRadio {    
  color: #C2CDDC;
  position: absolute;
  left: -30px;
  top: 35%;
}
.outerCard .selectionRadio span { 
  font-size: 35px;
  position: relative;
  right: 10px;
}
.outerCard .listing-box {
  width: 100%;
}
.fa-check-circle.selectedItem {
  color: #4B51DD;
  position: absolute !important;
  right: 10px;
}
/** Card Section -- END **/

@media only screen and (min-width:1025px) {
  .outerCard .selectionRadioBefore {opacity: 0;}
  /* .outerCard:hover .selectionRadio {opacity: 1;} */
  .outerCard:hover .hoverClass {
    opacity: 1;
  }
}